import { getCookie } from 'src/common/token';
// //*****sn.emso.vn*****
export let USER_TOKEN = '';
let tokenUser: any = getCookie();
if (tokenUser) {
  USER_TOKEN = tokenUser;
}
export const BASE_ROOT_MARKET = process.env.REACT_APP_BASE_ROOT_MARKET;
export const BASE_ROOT = process.env.REACT_APP_BASE_ROOT;
// export const BASE_ROOT = 'https://mxh.emso.asia';

// // rocket chat production env
export const urlRocketChat = process.env.REACT_APP_URL_ROCKET_CHAT;
export const urlWebSocket = process.env.REACT_APP_URL_WEB_SOCKET;

export const TOKEN_VIDEO_UPLOAD = process.env.REACT_APP_TOKEN_VIDEO_UPLOAD;

export const urlAdminEmso = process.env.REACT_APP_URL_ADMIN_EMSO;
export const urlWebEmso = process.env.REACT_APP_URL_WEB_EMSO;
export const urlStreamingSocket = process.env.REACT_APP_URL_STREAMING_SOCKET;
export const urlNotificationApi = process.env.REACT_APP_URL_NOTIFICATION_API;
export const urlNotificationSocket =
  process.env.REACT_APP_URL_NOTIFICATION_SOCKET;

//*****cmc-sn.emso.vn*****
// export let USER_TOKEN = '';
// let tokenUser = localStorage.getItem('token');
// if (tokenUser) {
//   USER_TOKEN = tokenUser;
// }

// export const BASE_ROOT = 'https://cmc-sn.emso.vn';
// // export const BASE_ROOT = 'https://mxh.emso.asia';

// // // rocket chat production env
// export const urlRocketChat = 'https://chat.emso.vn';
// export const urlWebSocket = 'wss://chat.emso.vn/websocket';

// export const TOKEN_VIDEO_UPLOAD = '21fbd3b2e427d9b9bae405e6480695e6bd233e44';

// export const urlAdminEmso = 'https://admin.emso.vn';
// export const urlWebEmso = 'https://sn-fe.emso.vn';
// export const urlStreamingSocket = 'wss://comment-ws.emso.vn/api/v1/streaming';
// export const urlNotificationApi = 'https://notification-api.emso.vn';
// export const urlNotificationSocket = 'https://notification-ws.emso.vn';

// *****cmc-sn.emso.vn*****
// export let USER_TOKEN = '';
// let tokenUser: any = getCookie();
// if (tokenUser) {
//   USER_TOKEN = tokenUser;
// }

// export const BASE_ROOT_MARKET = 'https://cmc-marketplace.emso.vn';
// export const BASE_ROOT = 'https://cmc-sn.emso.vn';
// // export const BASE_ROOT = 'https://mxh.emso.asia';

// // // rocket chat production env
// export const urlRocketChat = 'https://chat.emso.vn';
// export const urlWebSocket = 'wss://chat.emso.vn/websocket';

// export const TOKEN_VIDEO_UPLOAD = '21fbd3b2e427d9b9bae405e6480695e6bd233e44';

// export const urlAdminEmso = 'https://cmc-admin.emso.vn';
// export const urlWebEmso = 'https://cmc-fe.emso.vn';
// export const urlStreamingSocket = 'wss://comment-ws.emso.vn/api/v1/streaming';
// export const urlNotificationApi = 'https://notification-api.emso.vn';
// export const urlNotificationSocket = 'https://notification-ws.emso.vn';

// const baseRootPtube = 'https://pt3.emso.vn';
// export const urlRocketChat = 'https://chat.emso.asia';
// export const urlWebSocket = 'wss://chat.emso.asia/websocket';

// // rocket chat dev env
// export const urlRocketChat = 'http://localhost:3000';
// export const urlWebSocket = 'ws://localhost:3000/websocket';
