import { Typography } from '@mui/material';
import React, { Suspense, lazy, memo } from 'react';
import { Switch } from 'react-router-dom';
import Loading from 'src/components/Loading/Loading';
import { PATHS } from 'src/constants/paths';
import AuthenticatedGuard from 'src/guards/AuthenticatedGuard';
const LearnSpace = lazy(() => import('src/pages/LearnSpace/Index'));
const Project = lazy(() => import('src/pages/Project/Index'));
const ReportRecruit = lazy(() => import('src/pages/Recruit/ReportRecruit'));
const Event = lazy(() => import('src/pages/Event/index'));

const MusicSpace = lazy(() => import('src/pages/Music/Index'));
const Report = lazy(() => import('src/pages/Report/Index'));

const ConfigMarketPlaces = lazy(
  () => import('src/pages/ConfigMarketPlaces/index')
);

const DebtLogs = lazy(() => import('src/pages/DebtLogs/index'));
const DetailDebts = lazy(() => import('src/pages/DebtLogs/DetailDebts'));
const ApprovalProducts = lazy(() => import('src/pages/ApprovalProducts/index'));
const Brand = lazy(() => import('src/pages/Brand/index'));
const ReportViolations = lazy(() => import('src/pages/ReportViolations/index'));

const Campaign = lazy(() => import('src/pages/Campaign/Index'));
const Voucher = lazy(() => import('src/pages/Voucher/index'));
const Product = lazy(() => import('src/pages/Product/Index'));
const MerchantPayment = lazy(() => import('src/pages/MerchantPayment'));
const Promotion = lazy(() => import('src/pages/Promotion/index'));
const NotiMKP = lazy(() => import('src/pages/NotiMKP/index'));
const PopupLandingPage = lazy(() => import('src/pages/PopupLandingPage/index'));

const OrderFailed = lazy(() => import('src/pages/OrderFailed/index'));
const RefundRequest = lazy(() => import('src/pages/RefundRequest/index'));
const PostChart = lazy(() => import('src/pages/PostChart/Index'));
const Feed = lazy(() => import('src/pages/Feed/Index'));
const Trending = lazy(() => import('src/pages/Trending/index'));
const Verified = lazy(() => import('src/pages/Verified/Index'));
const EnableMoney = lazy(() => import('src/pages/EnableMoney'));
const Complain = lazy(() => import('src/pages/Complain/index'));
const BloodDonations = lazy(() => import('src/pages/BloodDonations/index'));
function Routes(props) {
  const { refScroll } = props;
  const routes = [
    {
      path: [PATHS.LEARN_SPACE, PATHS.LEARN_MINE],
      component: <LearnSpace refScroll={refScroll} />
    },
    {
      path: [PATHS.PROJECT, PATHS.PROJECT_MINE, PATHS.PROJECT_REQUEST],
      component: <Project refScroll={refScroll} />
    },
    {
      path: [PATHS.RECRUIT],
      component: <ReportRecruit refScroll={refScroll} />
    },
    {
      path: [PATHS.EVENT],
      component: <Event refScroll={refScroll} />
    },
    {
      path: [PATHS.MUSIC_SPACE, PATHS.MUSIC_MINE],
      component: <MusicSpace />
    },
    {
      path: [PATHS.REPORT, PATHS.REPORT_MINE],
      component: <Report refScroll={refScroll} />
    },

    {
      path: [PATHS.CONFIG_MARKETPLACES],
      component: <ConfigMarketPlaces refScroll={refScroll} />
    },
    {
      path: [PATHS.DEBT_LOGS],
      component: <DebtLogs refScroll={refScroll} />
    },
    {
      path: [PATHS.PAYABLES_LOGS],
      component: <DetailDebts debt_type="payables" />
    },
    {
      path: [PATHS.RECEIVABLES_LOGS],
      component: <DetailDebts debt_type="receivables" />
    },
    {
      path: [PATHS.APPROVAL_PRODUCTS],
      component: <ApprovalProducts refScroll={refScroll} />
    },
    {
      path: [PATHS.APPROVAL_BRAND],
      component: <Brand refScroll={refScroll} />
    },
    {
      path: [PATHS.REPORT_VIOLATIONS],
      component: <ReportViolations refScroll={refScroll} />
    },

    {
      path: [PATHS.CAMPAIGNS],
      component: <Campaign refScroll={refScroll} />
    },
    {
      path: [PATHS.VOUCHERS],
      component: <Voucher refScroll={refScroll} />
    },

    {
      path: [PATHS.PRODUCT],
      component: <Product refScroll={refScroll} />
    },

    {
      path: [PATHS.MERCHANT_PAYMENT_REQUEST],
      component: <MerchantPayment refScroll={refScroll} />
    },
    {
      path: [PATHS.PROMOTION],
      component: <Promotion refScroll={refScroll} />
    },

    {
      path: [PATHS.NOTI_MKP],
      component: <NotiMKP refScroll={refScroll} />
    },
    {
      path: [PATHS.POPUP_LANDING_PAGE],
      component: <PopupLandingPage refScroll={refScroll} typeTable="popup" />
    },
    {
      path: [PATHS.BANNER_SHOP_MALL],
      component: <PopupLandingPage refScroll={refScroll} typeTable="banner" />
    },

    {
      path: [PATHS.ORDER_FAILED],
      component: <OrderFailed refScroll={refScroll} />
    },

    {
      path: [PATHS.REFUND_REQUESTS],
      component: <RefundRequest refScroll={refScroll} />
    },

    {
      path: [PATHS.POST_CHART],
      component: <PostChart />
    },
    {
      path: [PATHS.FEED],
      component: <Feed refScroll={refScroll} />
    },

    {
      path: [PATHS.TRENDING],
      component: <Trending refScroll={refScroll} />
    },
    {
      path: [PATHS.COMPLAIN],
      component: <Complain type={'complain'} />
    },
    { path: [PATHS.BLOOD_DONNATIONS], component: <BloodDonations /> },
    {
      path: [PATHS.REPORT_SYSTEM_ERROR],
      component: <Complain type={'system_error'} />
    },
    {
      path: [
        PATHS.ENABLE_MONEY,
        PATHS.ENABLE_MONEY_USERS,
        PATHS.ENABLE_MONEY_PAGES
      ],
      component: <EnableMoney />
    },
    {
      path: [PATHS.REPORT_SYSTEM_ERROR],
      component: <Typography>Chưa có dữ liệu</Typography>
    },
    {
      path: [PATHS.VERIFY, PATHS.VERIFY_SUBJECT],
      component: <Verified refScroll={refScroll} />
    },
    {
      path: [PATHS.ADVERTISING_ACCOUNT],
      component: <Typography>Chưa có dữ liệu</Typography>
    },
    {
      path: [PATHS.FINANCIAL_CONTROL],
      component: <Typography>Chưa có dữ liệu</Typography>
    }
  ];
  return (
    <Switch>
      {routes.map((item, index) => {
        return (
          <AuthenticatedGuard
            key={index}
            path={item.path}
            exact
            component={() => (
              <Suspense fallback={<Loading />}>{item.component}</Suspense>
            )}
          />
        );
      })}
    </Switch>
  );
}

export default memo(Routes);
