import * as types from 'src/constants/store/setting';

export const getSettingReq = () => ({
  type: types.GET_SETTING_REQ
});

export const getSettingSuccess = (data: any) => ({
  type: types.GET_SETTING_SUCCESS,
  payload: {
    data
  }
});

export const setPreviewImg = (url: string) => {
  return {
    type: types.SET_PREVIEW_IMG,
    payload: {
      data: url
    }
  };
};

export const resetPreviewImg = () => ({
  type: types.RESET_PREVIEW_IMG
});
