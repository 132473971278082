import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampaignIcon from '@mui/icons-material/Campaign';
import DoneIcon from '@mui/icons-material/Done';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import FlagIcon from '@mui/icons-material/Flag';
import GroupsIcon from '@mui/icons-material/Groups';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import NotesIcon from '@mui/icons-material/Notes';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import PersonIcon from '@mui/icons-material/Person';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import ReportIcon from '@mui/icons-material/Report';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SchoolIcon from '@mui/icons-material/School';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TaskIcon from '@mui/icons-material/Task';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Drawer, Toolbar } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { PATHS } from 'src/constants/paths';
import ListButton from '../List/ListButton';
import { WarningAmberOutlined } from '@mui/icons-material';

const DRAWER_WIDTH = 260;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'white'
  },
  drawerOpen: {
    overflow: 'hidden',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    // backgroundColor: `${theme.palette.myBackgroundColor?.secondary} !important`
    backgroundColor: '#373737'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: 53
    },
    // backgroundColor: `${theme.palette.myBackgroundColor?.secondary} !important`
    backgroundColor: '#373737'
  },

  list: {},
  listItem: {
    '&:hover': {
      backgroundColor: '#e5e5e5',
      '& span': {
        color: '#000'
      },
      '& div i': {
        color: '#000'
      }
    }
  },
  listItemSelect: {
    backgroundColor: '#5e5e5e',
    '& span': {
      color: '#000',
      fontSize: 'white',
      fontWeight: 700
    },
    '& div i': {
      color: '#000',
      fontSize: 'white',
      fontWeight: 700
    }
  },
  listIconOpen: {
    minWidth: '30px !important'
  },
  listIconClose: {},
  icon: {
    textAlign: 'center',
    '& i': {
      fontSize: 18,
      color: '#828282'
    }
  },
  textIcon: {
    fontSize: 16,
    fontWeight: 400,
    color: '#828282'
  },
  textHeader: {
    padding: 10,
    fontSize: 20,
    fontWeight: 400,
    color: '#000 !important',
    height: 38
  }
}));

export default function MiniDrawer(props) {
  let { title, icon, dataItems } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match: any = useRouteMatch();
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });

  const InitialDataItems = [
    {
      text: 'Report',
      icon: <i className="fas fa-cog"></i>,
      link: PATHS.REPORT
    },
    {
      text: 'Learn Space',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.LEARN_SPACE
    },
    {
      text: 'Grow Space',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.PROJECT
    },
    {
      text: 'Music Space',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.MUSIC_SPACE
    },
    {
      text: 'Banner',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.CAMPAIGNS
    },
    {
      text: 'Product',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.PRODUCT
    },
    {
      text: 'Post Chart',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.POST_CHART
    },
    {
      text: 'Bảng tin',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.FEED
    },
    {
      text: 'Xác minh',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.VERIFY
    },
    {
      text: 'Khiếu nại',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.COMPLAIN
    },
    {
      text: 'Báo lỗi hệ thống',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.REPORT_SYSTEM_ERROR
    },
    {
      text: 'Tài khoản quảng cáo',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.ADVERTISING_ACCOUNT
    },
    {
      text: 'Đối soát tài chính',
      icon: <i className="fas fa-smile-wink"></i>,
      link: PATHS.FINANCIAL_CONTROL
    }
  ];

  if (!dataItems) dataItems = InitialDataItems;

  const listMenuReport = [
    {
      id: 'report',
      title: 'Báo cáo vi phạm',
      styleTitle: { fontWeight: '600' },
      icon: <ReportIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'posts',
          title: 'Bài viết',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <NotesIcon />,
          action: () => {
            history.push(`/report/posts`);
          }
        },
        {
          id: 'users',
          title: 'Người dùng',
          styleTitle: { fontWeight: '400' },
          icon: <PersonIcon />,
          action: () => {
            history.push(`/report/users`);
          }
        },
        {
          id: 'pages',
          title: 'Trang',
          styleTitle: { fontWeight: '400' },
          icon: <FlagIcon />,
          action: () => {
            history.push(`/report/pages`);
          }
        },
        {
          id: 'groups',
          title: 'Nhóm',
          styleTitle: { fontWeight: '400' },
          icon: <GroupsIcon />,
          action: () => {
            history.push(`/report/groups`);
          }
        }
      ]
    },
    {
      id: 'home_learn',
      title: 'Học tập',
      styleTitle: { fontWeight: '600' },
      icon: <SchoolIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      itemChildren: [
        {
          id: 'learnSpace',
          title: 'Phê duyệt khóa học',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <VerifiedUserIcon />,
          action: () => {
            history.push(PATHS.LEARN_SPACE);
          }
        },
        {
          id: 'learns_report',
          title: 'Báo cáo khóa học',
          styleTitle: { fontWeight: '400' },
          icon: <TaskIcon />,
          action: () => {
            history.push(`/learns/learns_report`);
          }
        }
      ],
      position: 'top'
    },
    {
      id: 'recruitment',
      title: 'Tuyển dụng',
      styleTitle: { fontWeight: '600' },
      icon: <PersonSearchIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      itemChildren: [
        {
          id: 'recruit_approval',
          title: 'Phê duyệt tuyển dụng',
          styleTitle: { fontWeight: '400' },

          icon: <i className="fa-solid fa-user-plus"></i>,
          action: () => {
            history.push(`/recruit/approval`);
          }
        },
        {
          id: 'recruit_report',
          title: 'Báo cáo tuyển dụng',
          styleTitle: { fontWeight: '400' },
          icon: <i className="fa-solid fa-user-minus"></i>,
          action: () => {
            history.push(`/recruit/report`);
          }
        }
      ],
      position: 'top'
    },

    {
      id: 'event',
      title: 'Sự kiện',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-solid fa-calendar"></i>,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      itemChildren: [
        {
          id: 'event_approval',
          title: 'Phê duyệt sự kiện',
          styleTitle: { fontWeight: '400' },

          icon: <i className="fa-solid fa-calendar-check"></i>,
          action: () => {
            history.push(`/event/approval`);
          }
        }
      ],
      position: 'top'
    },

    {
      id: 'home_grow',
      title: 'Gọi vốn',
      styleTitle: { fontWeight: '600' },
      icon: <PaidIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'growSpace',
          title: 'Phê duyệt dự án',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: (
            <i
              className="fa-regular fa-ballot-check"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(PATHS.PROJECT);
          }
        },
        {
          id: 'request_invests',
          title: 'Yêu cầu đầu tư dự án',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <VerifiedUserIcon />,
          action: () => {
            history.push(PATHS.PROJECT_REQUEST);
          }
        },
        {
          id: 'growSpace_report',
          title: 'Báo cáo dự án',
          styleTitle: { fontWeight: '400' },
          icon: <TaskIcon />,
          action: () => {
            history.push(`/projects/growSpace_report`);
          }
        }
      ]
    },
    {
      id: 'musicSpace',
      title: 'Âm nhạc',
      styleTitle: { fontWeight: '600' },
      icon: <LibraryMusicIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'music_song',
          title: 'Phê duyệt tác phẩm',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: (
            <i
              className="fa-solid fa-hexagon-check"
              style={{ fontSize: '22px' }}
            ></i>
          ),
          action: () => {
            history.push('/music_space/music_song');
          }
        },
        {
          id: 'music_album',
          title: 'Phê duyệt album',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <VerifiedUserIcon />,
          action: () => {
            history.push('/music_space/music_album');
          }
        },
        {
          id: 'add_sort_moment',
          title: 'Thêm đoạn nhạc ngắn',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <PlaylistAddCircleIcon />,
          action: () => {
            history.push('/music_space/add_sort_moment');
          }
        },
        {
          id: 'report',
          title: 'Báo cáo bài hát',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <WarningAmberOutlined />,
          action: () => {
            history.push('/music_space/report');
          }
        }
      ]
    },
    {
      id: 'product_mkp',
      title: 'Sản phẩm MKP',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-sharp fa-regular fa-box-open"></i>,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'approval_products',
          title: 'Duyệt sản phẩm ',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <i className="fa-solid fa-road"></i>,
          action: () => {
            history.push('/approval_products');
          }
        },
        {
          id: 'approval_brand',
          title: 'Duyệt thương hiệu',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <i className="fa-solid fa-sitemap"></i>,
          action: () => {
            history.push('/approval_brand');
          }
        },
        {
          id: 'report_violations',
          title: 'Sản phẩm bị báo cáo',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <i className="fa-solid fa-flag"></i>,
          action: () => {
            history.push('/report_violations');
          }
        },
        {
          id: 'review_report',
          title: 'Đánh giá bị báo cáo',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <i className="fa-light fa-comment-exclamation"></i>,
          action: () => {
            history.push('/report_violations?type=review_report');
          }
        },
        {
          id: 'product',
          title: 'Thông tin sản phẩm',
          styleTitle: { fontWeight: '400' },
          icon: <EventNoteOutlinedIcon />,
          action: () => {
            history.push(PATHS.PRODUCT);
          }
        }
      ]
    },
    {
      id: 'order_mkp',
      title: 'Đơn hàng MKP',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-solid fa-file-invoice"></i>,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'order_failed',
          title: 'Quản lý đơn giao không thành công',
          styleTitle: { fontWeight: '400', whiteSpace: 'normal' },
          icon: <i className="fa-regular fa-truck-clock"></i>,
          action: () => {
            history.push('/order_failed');
          },
          styleText: { flex: 1 }
        },
        {
          id: 'refund_requests',
          title: 'Quản lý đơn trả hàng',
          styleTitle: { fontWeight: '400', whiteSpace: 'normal' },
          icon: <i className="fa-solid fa-person-walking-arrow-loop-left"></i>,
          action: () => {
            history.push('/refund_requests');
          },
          styleText: { flex: 1 }
        }
      ]
    },
    {
      id: 'finance_mkp',
      title: 'Tài chính MKP',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-solid fa-money-check-dollar-pen"></i>,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'statistical_finace',
          title: 'Thống kê tài chính',
          styleTitle: { fontWeight: '400' },
          icon: <i className="fa-regular fa-chart-gantt"></i>,
          action: () => {
            //làm sau
          }
        },
        {
          id: 'merchant_payment_requests',
          title: 'Yêu cầu thanh toán',
          styleTitle: { fontWeight: '400' },
          icon: <PaymentsIcon />,
          action: () => {
            history.push(PATHS.MERCHANT_PAYMENT_REQUEST);
          }
        },
        {
          id: 'config_marketplaces',
          title: 'Phí bán hàng',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <i className="fa-solid fa-octagon-divide"></i>,
          action: () => {
            history.push('/config_marketplaces');
          }
        },
        {
          id: 'debt_logs',
          title: 'Quản lý công nợ',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <i className="fa-solid fa-arrows-turn-to-dots"></i>,
          action: () => {
            history.push('/debt_logs/payables');
          }
        }
      ]
    },
    {
      id: 'marketing',
      title: 'Marketing MKP',
      styleTitle: { fontWeight: '600' },
      icon: <CampaignIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'campaign',
          title: 'Chiến dịch',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <ViewCarouselIcon />,
          action: () => {
            history.push('/campaigns');
          }
        },
        {
          id: 'voucher',
          title: 'Voucher',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <VerifiedUserIcon />,
          action: () => {
            history.push(`/vouchers`);
          }
        },
        {
          id: 'promotion',
          title: 'Khuyến mãi',
          styleTitle: { fontWeight: '400' },
          icon: <i className="fa-solid fa-rectangle-ad"></i>,
          action: () => {
            history.push('/promotion');
          }
        },
        {
          id: 'noti_mkp',
          title: 'Thông báo MKP',
          styleTitle: { fontWeight: '400' },
          icon: <i className="fa-sharp fa-solid fa-bell"></i>,
          action: () => {
            history.push('/noti_mkp');
          }
        },
        {
          id: 'popup_landing_page',
          title: 'Popup landing page',
          styleTitle: { fontWeight: '400' },
          icon: <i className="fa-solid fa-globe"></i>,
          action: () => {
            history.push('/popup_landing_page');
          }
        },
        {
          id: 'banner_shop_mall',
          title: 'Banner shop mall',
          styleTitle: { fontWeight: '400' },
          icon: <i className="fa-regular fa-image-landscape"></i>,
          action: () => {
            history.push('/banner_shop_mall');
          }
        }
      ]
    },
    {
      id: 'managePost',
      title: 'Quản lý bài viết',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-solid fa-chart-simple"></i>,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'feed',
          title: 'Bảng tin',
          styleTitle: { fontWeight: '600' },
          icon: <FeedIcon />,
          action: () => {
            history.push(PATHS.FEED);
          },
          styleChildren: { marginTop: '5px' }
        },
        {
          id: 'post_chart',
          title: 'Thống kê',
          styleChildren: { marginTop: '5px' },
          styleTitle: { fontWeight: '600' },
          icon: <i className="fa-sharp fa-solid fa-chart-pie"></i>,
          action: () => {
            history.push(PATHS.POST_CHART);
          }
        },
        {
          id: 'trending',
          title: 'Trending',
          styleChildren: { marginTop: '5px' },
          styleTitle: { fontWeight: '600' },
          icon: <i className="fa-solid fa-chart-mixed"></i>,
          action: () => {
            history.push(PATHS.TRENDING);
          }
        }
      ]
    },

    {
      id: 'enable_money',
      title: 'Bật kiếm tiền',
      styleTitle: { fontWeight: '600' },
      icon: <VerifiedUserIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'enable_users',
          title: 'Người dùng bật kiếm tiền',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: (
            <i
              className="fa-solid fa-money-check"
              style={{
                fontSize: '20px'
              }}
            ></i>
          ),
          action: () => {
            history.push(PATHS.ENABLE_MONEY_USERS);
          }
        },
        {
          id: 'enable_pages',
          title: 'Trang bật kiếm tiền',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <VerifiedUserIcon />,
          action: () => {
            history.push(PATHS.ENABLE_MONEY_PAGES);
          }
        }
      ]
    },
    {
      id: 'verify',
      title: 'Xác minh',
      styleTitle: { fontWeight: '600' },
      icon: <VerifiedIcon />,
      accordion: true,
      style: { height: 36, width: 36 },
      styleButton: { marginLeft: '-5px' },
      position: 'top',
      itemChildren: [
        {
          id: 'verify_users',
          title: 'Xác minh người dùng',
          styleTitle: { fontWeight: '400' },
          styleChildren: { marginTop: '5px' },
          icon: <VerifiedUserIcon />,
          action: () => {
            history.push(`/verifies/verify_users`);
          }
        },
        {
          id: 'verify_pages',
          title: 'Xác minh trang',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              className="fa-sharp fa-regular fa-check-to-slot"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(`/verifies/verify_pages`);
          }
        },
        // {
        //   id: 'verify_blood_donnations',
        //   title: 'Xác minh trang hiến máu',
        //   styleTitle: { fontWeight: '400' },
        //   icon: <TaskIcon />,
        //   action: () => {
        //     history.push(`/verifies/verify_blood_donnations`);
        //   }
        // },
        {
          id: 'public_figure',
          title: 'Người của công chúng',
          styleTitle: { fontWeight: '400' },
          icon: <DoneIcon />,
          action: () => {
            history.push(`/verifies/public_figure`);
          }
        }
        // {
        //   id: 'public_page',
        //   title: 'Trang của công chúng',
        //   styleTitle: { fontWeight: '400' },
        //   icon: <i className="fa-solid fa-flag"></i>,
        //   action: () => {
        //     history.push(`/verifies/public_page`);
        //   }
        // }
      ]
    },
    {
      id: 'complain',
      title: 'Khiếu nại',
      styleTitle: { fontWeight: '600' },
      icon: <PhoneInTalkIcon />,
      action: () => {
        history.push(PATHS.COMPLAIN);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    // {
    //   id: 'blood_donations',
    //   title: 'Hiến máu',
    //   styleTitle: { fontWeight: '600' },
    //   icon: <VolunteerActivismIcon />,
    //   action: () => {
    //     history.push(PATHS.BLOOD_DONNATIONS);
    //   },
    //   style: { height: 36, width: 36 },
    //   position: 'top'
    // },
    {
      id: 'report_system_error',
      title: 'Báo lỗi hệ thống',
      styleTitle: { fontWeight: '600' },
      icon: <ReportProblemIcon />,
      action: () => {
        history.push(PATHS.REPORT_SYSTEM_ERROR);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'advertising_account',
      title: 'Tài khoản quảng cáo',
      styleTitle: { fontWeight: '600' },
      icon: <SupervisedUserCircleIcon />,
      action: () => {
        history.push(PATHS.ADVERTISING_ACCOUNT);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'financial_control',
      title: 'Đối soát tài chính',
      styleTitle: { fontWeight: '600' },
      icon: <AttachMoneyIcon />,
      action: () => {
        history.push(PATHS.FINANCIAL_CONTROL);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    }
  ];

  React.useEffect(() => {
    let { key } = match?.params;
    switch (match.path) {
      case PATHS.REPORT:
        setSelectedIndex('report');
        break;
      case PATHS.REPORT_MINE:
        if (key) {
          setSelectedIndex(key);
        } else {
          setSelectedIndex('report');
        }
        break;
      case PATHS.LEARN_SPACE:
        setSelectedIndex('learnSpace');
        break;
      case PATHS.LEARN_MINE:
        if (key) {
          setSelectedIndex(key);
        } else {
          setSelectedIndex('learnSpace');
        }
        break;
      case PATHS.PROJECT:
        setSelectedIndex('growSpace');
        break;
      case PATHS.PROJECT_MINE:
        if (key) {
          setSelectedIndex(key);
        } else {
          setSelectedIndex('growSpace');
        }
        break;

      case PATHS.RECRUIT:
        if (key === 'report') {
          setSelectedIndex('recruit_report');
        } else {
          setSelectedIndex('recruit_approval');
        }
        break;

      case PATHS.EVENT:
        if (key === 'approval') {
          setSelectedIndex('event_approval');
        }
        break;

      case PATHS.MUSIC_SPACE:
        setSelectedIndex('musicSpace');
        break;
      case PATHS.MUSIC_MINE:
        if (key) {
          setSelectedIndex(key);
        } else {
          setSelectedIndex('musicSpace');
        }
        break;
      case PATHS.CONFIG_MARKETPLACES:
        setSelectedIndex('config_marketplaces');
        break;
      case PATHS.DEBT_LOGS:
        setSelectedIndex('debt_logs');
        break;
      case PATHS.APPROVAL_PRODUCTS:
        setSelectedIndex('approval_products');
        break;

      case PATHS.APPROVAL_BRAND:
        setSelectedIndex('approval_brand');
        break;

      case PATHS.REPORT_VIOLATIONS:
        if (location.search === '?type=review_report') {
          setSelectedIndex('review_report');
          break;
        }
        setSelectedIndex('report_violations');
        break;

      case PATHS.CAMPAIGNS:
        setSelectedIndex('campaign');
        break;
      case PATHS.VOUCHERS:
        setSelectedIndex('voucher');
        break;
      case PATHS.MERCHANT_PAYMENT_REQUEST:
        setSelectedIndex('merchant_payment_requests');
        break;

      case PATHS.PROMOTION:
        setSelectedIndex('promotion');
        break;
      case PATHS.NOTI_MKP:
        setSelectedIndex('noti_mkp');
        break;

      case PATHS.POPUP_LANDING_PAGE:
        setSelectedIndex('popup_landing_page');
        break;

      case PATHS.BANNER_SHOP_MALL:
        setSelectedIndex('banner_shop_mall');
        break;

      case PATHS.ORDER_FAILED:
        setSelectedIndex('order_failed');
        break;

      case PATHS.REFUND_REQUESTS:
        setSelectedIndex('refund_requests');
        break;

      case PATHS.ENABLE_MONEY:
        setSelectedIndex('enable_money');
        break;
      case PATHS.ENABLE_MONEY_USERS:
        setSelectedIndex('enable_users');
        break;
      case PATHS.ENABLE_MONEY_PAGES:
        setSelectedIndex('enable_pages');
        break;
      case PATHS.PRODUCT:
        setSelectedIndex('product');
        break;
      case PATHS.POST_CHART:
        setSelectedIndex('post_chart');
        break;
      case PATHS.TRENDING:
        setSelectedIndex('trending');
        break;
      case PATHS.FEED:
        setSelectedIndex('feed');
        break;
      case PATHS.COMPLAIN:
        setSelectedIndex('complain');
        break;
      case PATHS.BLOOD_DONNATIONS:
        setSelectedIndex('blood_donations');
        break;
      case PATHS.REPORT_SYSTEM_ERROR:
        setSelectedIndex('report_system_error');
        break;
      case PATHS.VERIFY_SUBJECT:
        if (key) {
          setSelectedIndex(key);
        } else {
          setSelectedIndex('verify');
        }
        break;
      case PATHS.VERIFY:
        setSelectedIndex('verify');
        break;
      case PATHS.FINANCIAL_CONTROL:
        setSelectedIndex('financial_control');
        break;
      case PATHS.ADVERTISING_ACCOUNT:
        setSelectedIndex('advertising_account');
        break;
      default:
        setSelectedIndex('learnSpace');
    }
  }, [
    JSON.stringify(match.path),
    JSON.stringify(match.params),
    location.search
  ]);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const renderIcons = () => {
    return (
      <>
        <p className={classes.textHeader}>{openDrawer ? `${title}` : icon}</p>
        <ListButton
          item={listMenuReport.filter(el => el.position === 'top')}
          selectedItem={selectedIndex}
          width={'250px'}
          styleAccordionDetail={{ padding: '3px 10px' }}
        />
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Drawer
        style={{ zIndex: 10 }}
        onMouseEnter={handleDrawerOpen}
        // onMouseLeave={handleDrawerClose}
        variant="persistent"
        anchor={'left'}
        open={true}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer
          })
        }}
        // hideBackdrop={true}
      >
        <Toolbar />
        {renderIcons()}
      </Drawer>
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </div>
  );
}
