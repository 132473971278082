import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Backdrop, Fade, Modal } from '@mui/material';
import { resetPreviewImg } from 'src/store/action/settingAction';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundcolor: 'red'
    }
  },
  img: {
    outline: 'none'
  }
}));

const DialogPreviewImage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { url, openPreview } = useSelector((state: any) => {
    return state.settingReducer.previewImg;
  });
  return (
    <Modal
      className={classes.modal}
      open={openPreview}
      onClose={() => dispatch(resetPreviewImg())}
      closeAfterTransition
    >
      <Fade in={openPreview} timeout={500} className={classes.img}>
        <img
          src={url}
          alt="preview_img"
          style={{ maxHeight: '90%', maxWidth: '90%' }}
        />
      </Fade>
    </Modal>
  );
};

export default DialogPreviewImage;
