import * as types from 'src/constants/store/setting';
const _ = require('lodash');

const initialState = {
  previewImg: {
    url: '',
    openPreview: false
  }
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SETTING_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return newState;
    }
    case types.SET_PREVIEW_IMG: {
      const { data } = action.payload;
      const newState = {
        ...state,
        previewImg: {
          url: data,
          openPreview: true
        }
      };
      return newState;
    }
    case types.RESET_PREVIEW_IMG: {
      const newState = {
        ...state,
        previewImg: {
          url: '',
          openPreview: false
        }
      };
      return newState;
    }
    default:
      return state;
  }
};
